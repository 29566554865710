import shopifyWarehouseGenericDefaults from '../shopify-warehouse-generic/config.js';

const wheelsFields = shopifyWarehouseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyWarehouseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-warehouse-generic'],
  ...shopifyWarehouseGenericDefaults,
  Widgets: [
    ...shopifyWarehouseGenericDefaults.Widgets.filter(
      (w) => !['FacetBarWheels', 'FacetBarTires'].includes(w.name),
    ),
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
  ],
};
