
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'focusable': 'false',
                'className': 'icon icon--search',
                'viewBox': '0 0 21 21',
                'role': 'presentation'
            }, _createElement('g', {
                'strokeWidth': '2',
                'stroke': 'currentColor',
                'fill': 'none',
                'fillRule': 'evenodd'
            }, _createElement('path', {
                'd': 'M19 19l-5-5',
                'strokeLinecap': 'square'
            }), _createElement('circle', {
                'cx': '8.5',
                'cy': '8.5',
                'r': '7.5'
            }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]